#Garantia {
    height: 100%;
  }
.img-compromiso{
    top: 0px;
    width: 100%;
    height: 100%;
}
.parrafo-garantia{
    padding: 35px 0px 35px;
    margin: 0px;
    font-size: 18px;
    text-align: left;
    font-family: POPPINS-LIGHT;
    color:#000000;
    text-align: left;
    line-height: 150%;
}
.img-garantia{
    color: #FFFFFF;
    z-index: 1;
    width: 95px;
    margin-top: 20px;
    object-fit: contain;
    transform: rotate(0.001turn);
    background: transparent 0% 0% no-repeat padding-box;
    mix-blend-mode: darken;
    position: absolute;
    right: 0px;
    top: -40px;
    height: auto;
    opacity: 1;
}
.btn-garantia{
    background-color: #F7F1EB;
    border: 2px solid #231F20;
    color: #231f20;
    font-family: POPPINS-LIGHT;
    font-size: 17px;
    width: 240px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    bottom: 10px;
}
.btn-garantia:hover{
    background-color: #231F20;
    border-color: #231F20;
    color: #F7F1EB;
}
.parrafo-g{
    font-size: 45px;
    font-family: MONTSERRAT-LIGHT;
    text-align: left;
    color: #000000;
    letter-spacing: 0px;
    left: 1114px;
    top: 200px;
    line-height: 30px;
}
.btn-garantia:hover a{
    text-decoration: none;
    color: #F7F1EB;
}
.d-garantia .derecho{
    width: 50%;
    color: black;
    position: relative;
    padding: 50px 80px 30px;
    hyphens: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
.d-garantia{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
}
.derecho-p{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.d-garantia .izquierdo{
    width: 50%;
    color: black;
    position: relative;
}
.d-garantia .izquierdo img {
    object-fit: cover;
}
.titulo-garantia{
    display: flex;
    padding: 10px 0px 0px;
    position: relative;
    width: 450px;
}
@media (min-width: 1600px){
    .d-garantia .izquierdo{
        width: 50%;
    }
    .d-garantia .derecho{
        padding: 80px;
    
    }
    .parrafo-g{
        font-size: 55px;
    }
    .parrafo-garantia{
        font-size: 22px;
        padding: 50px 0px 50px;
    }
    .btn-garantia{
        width: 250px;
        height: 60px;
        font-size: 18px;
        background-color: #F7F1EB;
    }
    .titulo-garantia{
        width: 580px;
        padding: 30px 0px 0px;
    }
    .img-garantia{
        top: -60px;
        width: 140px;
    }
  }
@media (max-width:1024px){
    .d-garantia{
        flex-direction: column;
    }
    .d-garantia .derecho{
        width: 100%;
        padding: 60px 70px 60px;
        height: 100%;
        justify-content: start;
    }
    .d-garantia .izquierdo{
        width: 100%;
    }   
    .btn-garantia{
        font-size: 17px;
        width: 230px;
        height: 50px;
    }
    .parrafo-garantia{
        width: 100%;
        padding: 40px 0px 40px;
    }
  }
@media (max-width:550px){
    .d-garantia{
        flex-direction: column;
        height: 100%;
    }
    .titulo-garantia{
        position: relative;
        width: 288px;
        padding: 0px 0px 0px;
        justify-content: center;
    }
    .d-garantia .derecho{
        width: 100%;
        padding: 40px 20px 40px;
        height: 320px;
    }
    .derecho-p{
        justify-content: space-between;
        height: 100%;
    }
    .parrafo-g{
        font-size: 25px;
        margin: 0px;
    }
    .img-compromiso{
        width: 100%;
        height: 100%;
    }
    .d-garantia .parrafo-garantia{
        font-size: 15px;
        padding: 15px 0px 15px;
    }
    .img-garantia{
        width: 80px;
        position: absolute;
        object-fit: contain;
        right: -36px;
        margin-top: 0px;
        top: -30px;
        height: auto;
    }
    .btn-garantia{
        height: 40px;
        font-size: 15px;
        width: 160px;
        bottom: 0px;

    }
  }
 @media (max-width:400px){
}
@media (max-width:370px){
    .parrafo-g{
        font-size: 30px;
    }
    .titulo-garantia{
        width: 310px;
    }
} 