
.derecho-personalizacion{
    width: 50%;
    display: flex;
    position: relative;
    object-fit: cover;
}
.izquierdo-personalizacion{
    width: 50%;
    flex-direction: column;
    padding: 20px 50px 20px
}
.izquierdo-personalizacion a{
    text-decoration: none;
}
.personalizacion-principal{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.btn-personalizacion:hover{
    background-color: #FFFFFF;
    color: #000;
    border-color: #FFFFFF;
}
.txt-personalizacion{
    width: 140px;
    height: 25px;
    color: #fff;
    font-family: MONTSERRAT-LIGHT;
    margin-top: 10%;
    font-size: 18px;
}
.txt-personalizacion2{
    font-size: 43px;
    font-family: MONTSERRAT-LIGHT;
    text-align: left;
    hyphens: none;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 550px;
}
.fondo2{
    background-color: black;
    overflow: hidden;
    display: flex;
}
.txt-personalizacion3{
    font-size: 18px;
    text-align: left;
    font-family: POPPINS-LIGHT;
    color:#FFFFFF;
    text-align: left;
    hyphens: none;
    line-height: 150%;
    width: 100%;
    padding: 25px 0px 25px;
}
.btn-personalizacion{
    background-color: black;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: POPPINS-LIGHT;
    font-size: 17px;
    width: 220px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.img-personalizacion{
    width: 100%;
}
   @media (min-width: 1600px){
    .izquierdo-personalizacion{
        padding: 20px 80px 10px;
        justify-content: start;
        display: flex;
    }
    .btn-personalizacion{
        width: 230px;
        height: 60px;
        font-size: 17px;
    }
    .txt-personalizacion3{
        font-size: 22px;
    }
   }
   @media (max-width:1024px){
    .personalizacion-principal{
        width: 100%;
        justify-content: center;
    }
    .fondo2{
        flex-direction: column;
    }
    .izquierdo-personalizacion{
        width: 100%;
        height: auto;
        flex-direction: column;
        display: flex;
        justify-content: start;
        padding: 20px 80px 50px;
    }
    .derecho-personalizacion{
        width: 100%;
    }
    .txt-personalizacion2{
        width: 100%;
    }
    .txt-personalizacion3{
        width: 100%;
        hyphens: none;
    }

  }
  @media (max-width:550px){
    .personalizacion-principal{
        width: 100%;
        height: 90%;
        justify-content: flex-start;
    }
    .txt-personalizacion{
        height: 0px;
        margin-top: 12px;
    }
    .txt-personalizacion2{
        font-size: 25px;
        margin-top: 20px;
    }
    .txt-personalizacion3{
        width: 100%;
        font-size: 15px;
        margin: 0px;
        padding: 20px 0px 20px;
    }
    .btn-personalizacion{
        width: 140px;
        font-size: 15px;
        height: 40px;

    }
    .izquierdo-personalizacion{
        height: 450px;
        justify-content: center;
        display: flex;
        padding: 40px 20px 40px;
    }
  }
 @media (max-width: 360px){
    .izquierdo-personalizacion {
        height: auto;
    }
    .txt-personalizacion2{
        font-size: 25px;
    }


} 
