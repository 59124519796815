.contenedor_header{
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    background-color: #F7F1EB;
    justify-content: space-evenly;
    align-items: center;
    z-index: 99;
    padding: 0px 80px;
    @media (min-width:1600px) {
        height: 100px;
        justify-content: space-between;
    }
    &.back_menu{
        background-color: #F7F1EB;
        .menu_logo{
            margin-top: 0;
            img{
                width: 150px;
                @media (min-width:1600px) {
                    width: 150px;
                }
            }
        }
    }
    &.ocultar{
        @media (max-width:1024px) {
            display: none;
        }
    }
    .menu_logo{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-top: 5px;
        img{
            width: 150px;
        }
    }
    .menu_telefono{
        text-align: right;
        width: 40%;
        display: flex;
        min-width: 600px;
        justify-content: space-between;
        @media (min-width:1600px){
            width: 40%;
        }
        a{
            font-family: "MONTSERRAT-LIGHT";
            font-size: 20px;
            color: #000;
            text-decoration: none;
            display: flex;
            align-items: center;

            @media (min-width:1600px) {
                font-size: 22px;
            }
        }

        a:hover{
            color: #9b8c7f;
        }
         .iconos_social{
            display: flex;
            width: 130px;
            justify-content: space-between;
            @media (min-width:1600px){
                width: 145px;
            }
             img{
                 width: 30px;
                 @media (max-width:1400px) {
                    width: auto;
                }
                 @media (max-width:1600px) {
                     width: 21px;
                     object-fit: contain;
                 }
             }
         } 
    }


}

header{
    width: 100%;
    height: 130px;
    position: fixed;
    display: none;
    justify-content: space-between;
    z-index: 999;
    @media (max-width: 550px) {
        height: 85px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 50px;
    }
    &.mostrar{
        @media (max-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
            background-color: #F7F1EB;
            height: 73px;
            z-index: 999;
        }
    }
    &.back_menu{
        background-color: #F7F1EB;
        .menu_logo{
            width: 55%;
            margin-top: 0;
            @media (max-width:1024px) {
                width: 60%;
            }
            @media (max-width: 550px) {
                width: 63%; 
            }
            @media (max-width: 990px) and (orientation: landscape) {
                width: 55%;
            }
            img{
                width: 50px;
                @media (max-width:1024px) {
                    width: 150px;
                }
                @media (max-width: 550px) {
                    width: 110px;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    width: 70px;
                }
            }
        }
        .btn-toggle {
            span {
                background-color: #000;
                &::before, &::after {
                    background-color:#000;
                }
            }
        }
    }
    .icon_menu{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 550px) {
           width: 20%;
        }
    }
    .menu_logo{
        width: 60%;
        display: flex;
        align-items: center;
        @media (max-width: 550px) {
            width: 63%;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            width: 55%;
        }
        img{
            width: 110px;
            margin-top: -20%;
            @media (max-width: 1024px){
                width: 150px;
                margin-top: 0%;
            }
            @media (max-width: 550px) {
                width: 110px;
                
            }
            @media (max-width: 990px) and (orientation: landscape) {
                width: 120px;
                margin-top: 0%;

            }
        }
    }
    &.menu-open span{
        background-color: transparent !important;
        &::before {
            top: 0 !important;
            transform: rotate(-45deg);
        }
        &::after {
            bottom: 0 !important;
            transform: rotate(45deg);
        }
    }
    &.opened{   
        background-color:#F7F1EB;
        height: 100vh;
        width: 56%;
        z-index: 9999;
        align-items: flex-start;
        @media (max-width: 1024px){
            width: 100%;
        }
        @media (max-width: 550px){
            width: 100%;
        }
        .menu{
            display: flex;
            flex-direction: column;
            width: 100%;
            font-family: MONTSERRAT-LIGHT;
            font-size: 16px;
            height: 75%;
            @media (max-width:1024px) {
                align-items: center;
            }
            @media (max-width:550px) {
               bottom: 80px;
              
            }
            ul{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                height: 100%;
                @media (min-width:1600px) {
                    justify-content: space-between;
                }
                li{
                    list-style: none;
                   a{
                       cursor: pointer;
                    color: #000;
                    text-decoration: none;
                    font-size: 32px;
                    font-family: MONTSERRAT-LIGHT;
                    @media (min-width:1600px) {
                        font-size: 30px;
                    }
                    @media (max-width:1024px){
                        font-size: 25px;
                    }
                    @media (max-width:550px) {
                        font-size: 22px;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                       font-size: 20px;
                    }
                   }
                   img{
                       width: 150px;
                       margin-top: 30px;
                       @media (min-width:1600px) {
                        
                        }
                   }
                }
            }
            .iconos_social{
                 width: 20%;
                 display: flex;
                 justify-content: space-between;
                 height: 89%;
                 @media (max-width: 1024px){
                    height: 70%;
                 }
                 @media (max-width: 550px){
                    height: 50%;
                    width: 35%;
                    align-items: center;
                 }
                 @media (max-width: 990px) and (orientation: landscape) {
                     width: 8%;
                
                                    }
             }
        }
        .icon_menu{
            height: 130px;
            @media (max-width: 550px) {
                height: 85px;

            }
            @media (max-width: 990px) and (orientation: landscape) {
                height: 50px;
            }
        }
        .menu_logo{
            height: 130px;
            @media (max-width: 550px) {
                height: 85px;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                height: 50px;
            }
        }
        &.back_menu .icon_logo{
            height: 80px !important;
        }
    }
    .menu{
        display: none;
        .descarga{
            text-align: center;
            color: white;
            font-size: 20px;
            @media (max-width: 990px) and (orientation: landscape) {
                font-size: 18px;
            }
        }
    }
    .btn-toggle {
        width: 50px;
        height: 50px;
        margin-right: 0px !important;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            margin-left: 0px;
            height: 2.8px;
            width: 60%;
            background-color: #231F20;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color:#231F20;
                position: absolute;
                left: 0;
                transition: width .5s;
            }
            @media (max-width:1024px){
                margin-top: 13px;
                margin-left: -5px;
              }
            @media (max-width:550px){
               margin-top: 11px;
                margin-left: -5px;
             }
            @media (max-width: 990px) and (orientation: landscape) {
                left: 0px;
                margin-top: 70%;
            }
    
            &::before {
                top: -8px;
            }
            &::after {
                bottom: -7px;
            }
            &::before, &::after {
                transition: all .3s;
            }
        }
    }
}