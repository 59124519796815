
/* .Cabecera{
    width:100%;
    padding:1em;
    background-color: #F7F1EB;    
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-family: MONTSERRAT-LIGHT;
    z-index: 99;
    font-size: 18px;
    position: fixed;
}
.imglogo{
    width: 30%;
    height: 30%;
    margin-left: 90px;
}
.redes{
    width: 60%;
    height: 60%;
}
.Cabecera-h1{}
.Cabecera-a{
}

.Cabecera-button{
    display:none;
    cursor: pointer;
}
.Cabecera-svg{
    width:2em;
    height:2em;
    fill:#000;
}
.Cabecera-nav{}
.Cabecera-ul{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
}
.Cabecera-li{
    margin: 0 .5em;
    text-decoration: none;
}
.cabecera-b{
    text-decoration: none;
}
@media(max-width:550px){
    .Cabecera-button{
        display: flex;

    }
    .Cabecera-nav{
        width:100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height:0vh;
        opacity: 1;
        overflow: hidden;
        transition: all .5s ease;
        font-size: 17px;
        background-color: rgba(247, 241, 235, 0.90);
    }
    .Cabecera-nav.isActive{
        height:50vh;
        opacity: 1;
    }
    .Cabecera-ul{
        flex-direction: column;
    }
    .Cabecera-li{
        margin: .5em 0;
    }
}
a:link {
 color: black; 
 text-decoration: none 
}
a:hover {color: #9b8c7f; text-decoration: none }

a:link, a:visited, a:active {
    text-decoration:none;
} */
.styles-calendly{
    margin: 0px;
    font-weight: lighter;
}
@media (max-width:550px){
    .styles-calendly{
    margin: 0px;
    }
}