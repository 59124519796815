#Galeria {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #F7F1EB;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  .contenedor-carrusel{
    display: flex;
    min-height: 600px;
  }
  .contenedor-carrusel .izquierdo{
    width: 50%;
    color: black;
    position: relative;
  }
  .contenedor-carrusel .izquierdo img{
    object-fit: contain;
  }
  .contenedor-carrusel .derecho{
    width: 50%;
    color: black;
    position: relative;
    padding: 60px 80px;
    hyphens: none;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100vh;
  }
  .swiper {
    width: 100%;
    height: 100%;
    background: transparent;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .swiper {

  }
.btn-maximizar{
  position: absolute;
  color: #FFFFFF;
  border: none;
  top: 20px;
  z-index: 1;
  right: 15px;
  width: 30px;
  height: 30px;
}
.parrafo{
    font-size: 45px;
    font-family: MONTSERRAT-LIGHT;
    text-align: left;
    color: #000000;
    letter-spacing: 0px;
    left: 1114px;
    top: 200px;
    width: 356px;
}
.parrafo2{
    width: 100%;
    font-size: 18px;
    text-align: left;
    font-family: POPPINS-LIGHT;
    color:#000000;
    text-align: left;
    line-height: 150%;
    padding: 20px 0px 20px;
}
.derecho-p-g{
  padding: 20px 31px 20px;
}
.btn-galeria{
    background-color: #F7F1EB;
    border: 2px solid #231F20;
    color: #231f20;
    font-family: MONTSERRAT-LIGHT;
    font-size: 17px;
    width: 165px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.btn-galeria:hover{
    background-color: #231F20;
    color:#F7F1EB;
    border-color: #231F20;
}
.swiper-button-prev{
    color: #F7F1EB;

}
.swiper-button-next{
    color: #F7F1EB;
}
@media (min-width: 1600px){
  .btn-maximizar{
    width: 40px;
  }
  .parrafo2{
    font-size: 22px;
    width: 100%;
  }
  .contenedor-carrusel .derecho{
  padding: 100px;
  }
  .parrafo{
    font-size: 50px;
  }

}
@media (max-width:1024px){
  .contenedor-carrusel{
    flex-direction: column;
  }
  .contenedor-carrusel .izquierdo{
    width: 100%;
  }
  .contenedor-carrusel .izquierdo .swiper .swiper-wrapper .swiper-slide img{
    margin-top: 0px;
  }
  .contenedor-carrusel .derecho{
    width: 100%;
    height: auto;
    justify-content: space-evenly;
  }
  .parrafo2{
    font-size: 18px;
    width: 100%;
  }
  .parrafo{
    width: 100%;
  }
  .btn-maximizar{
    width: 45px;
  }
  .swiper-button-prev{
  }
}
@media (max-width:550px){
  .contenedor-carrusel{
    flex-direction: column;
  }
  .contenedor-carrusel .izquierdo{
    width: 100%;
  }
  .contenedor-carrusel .derecho{
    width: 100%;
    padding: 40px 20px 40px;
    justify-content: start;
    height: auto;
  }
  .parrafo{
    font-size: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .parrafo2{
    font-size: 15px;
    width: 100%;
  }
  .derecho-p-g{
    padding: 0px 0px 0px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .swiper-button-prev{
    height: 10vw;
  }
  .btn-maximizar{
    width: 30px;
  }
  .btn-galeria{
    width: 120px;
    height: 40px;
    font-size: 15px;
  }
}

  
@media (max-width:280px){
.contenedor-carrusel .derecho{
  height: 76vh;
}
.parrafo{
  padding: 6px 27px;
}
.btn-galeria{
  font-size: 14px;
  width: 125px;
}


}


