.txt-alianzas{
    font-size: 43px;
    font-family: MONTSERRAT-LIGHT;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 60px;
}
.fondo-alianzas{
    background-color: #fff;
    display: flex;
    width: 100%;
    height: 100%;
}
.alianzas{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.slick-slider{
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.slick-slide img{
  width: 180px;
}

  .fondo-alianzas .alianzas .img-alianzas .swiper  .swiper-slide img {
    display: block;
    width: 170px;
  }
@media (min-width: 1600px){
  
  }
  @media (max-width:1024px){
    .fondo-alianzas{
        width: 100vw;
    }
    .slick-slide img{
      width: 130px;
    }
    }
  @media (max-width:550px){
    .img-alianzas{
        padding: 0px 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .slick-slide img{
      width: 80px;
    }
    .slick-slider{
      padding-bottom: 40px;
      padding-top: 40px;
    }
    .txt-alianzas{
        font-size: 30px;
        margin-top: 0px;
        padding: 20px 20px 0px;
        margin: 0px;
    }
  }
