.mapa{
    width:"600";
    height:"450";
    border:0; 
}
.maps-style{
    height: 700px;
}
@media (max-width:1024px){
    .maps-style{
        height: 400px;
    }
}