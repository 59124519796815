.img-logo-footer{
width:130px;
margin-bottom: 25px;

}
.fondo-footer{
    background-color: #fff;
    display: flex;
    padding: 20px 70px 0px;
    justify-content: space-between;
    align-items: center;
    height: 165px;
}
.direccion{
    flex-direction: column;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 115px;
    width: 22%;

}
.contacto-footer{
    flex-direction: column;
    text-align: left;
    height: 115px;
    width: 15%;

}
.redessociales-footer{
    flex-direction: column;
    text-align: left;
    height: 115px;
    width: 15%;
}
.redesS{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}
.redesS img{
    width: 20px;
    object-fit: contain;
}
.politica-footer{
    flex-direction: column;
    text-align: left;
    height: 115px;
    width: 22%;

}
.titulo-foot{
    color: #000000;
    font-size: 16px;
    letter-spacing: 0px;
    font-family: POPPINS-LIGHT;
    font-weight: bold;
    margin-bottom: 25px

    ;
}
.fondo-footer .contacto-footer a{
    text-decoration: none;
}
.fondo-footer .politica-footer a{
    text-decoration: none;
}
.fondo-footer .contacto-footer a:hover{
    color: #000000;
}
.fondo-footer .politica-footer a:hover{
    color: #000000;
}
.txt-foot{
margin: 0px;
width: 95%;
color: #000000;
font-size: 15px;
font-family: POPPINS-LIGHT;
margin-right: 10px;
margin-bottom: 0px;
}

.img-renderinc{
    width: 100px;

}
.txt-foot2{
    color: #000000;
    font-size: 15px;
    font-family: POPPINS-LIGHT;
    margin-right: 10px;
    margin-bottom: 0px;

}
.p-footer{
    color: #000000;
    font-size: 15px;
    font-family: POPPINS-LIGHT;
    margin-right: 10px;
    margin-bottom: 0px;
}
.logo-footer{
    align-items: center;
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
.logo-f{
    display: flex;
    align-items: center;
}
@media (min-width: 1600px){

  }
  @media (max-width:1024px){
    .fondo-footer{
        flex-wrap: wrap;
        height: auto;
    }
    .direccion{
        width: 50%;

    }
    .contacto-footer{
        width: 38%;
    }
    .redessociales-footer{
        width: 50%;
    }
    .politica-footer{
        width: 38%;
    }
    .redesS{
        width: 30%;
    }
    .img-foot{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .logo-footer{
        justify-content: center;
        padding: 0px 70px 20px;
    }
  }
  @media (max-width:550px){
    .direccion{
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 90px;
    }
    .txt-foot{
        width: 91%;
    }
    .titulo-foot{
        margin-bottom: 10px;
    }
    .contacto-footer{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
    }
    .redessociales-footer{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }
    .logo-footer{
        justify-content: center;
    }
    .fondo-footer{
        padding: 40px 20px 0px;
    }
    .politica-footer{
        width: 100%;
        display: flex;
        align-items: center;
        height: 100px;
    }
    .img-foot{
        justify-content: center;
    }
  }
  @media (max-width:280px){
    .fondo-footer{
        padding: 19px 46px 0px;
    }
  }
