.modalGarantia{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.548);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.close{
    position: absolute;
    top: 20px;
    right: 50px;
    color: white;
    font-size: 30px;
    cursor: pointer;
}
.Garantia{
    height: 95%;
    justify-content: center;
    display: flex;
}
img{
    width: 90%;
    height: 100%;  
}
@media (max-width:1024px){
    .Garantia{
        height: 50%;
    }
}
@media (max-width:550px){
    .Garantia{
        height: 35%;
    }
}