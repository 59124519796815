.txt-calendly{
    color:black;
    font-weight: bolder;
    margin-right: 20px;
    outline: none !important;
}
@media (max-width:550px){
    .txt-calendly{
        text-transform: uppercase;
    font-size: 22px;
    margin: 0px;
    }
}