.container-politica{
    width: 100%;
    height: 100%;
    hyphens: none;
}
.contenedor-p-politica{
    width: 100%;
    padding: 100px 250px 100px;
    display: flex;
    flex-direction: column;
}
.titulo-polit{
    font-size: 35px;
    text-align: center;
    font-family: MONTSERRAT-LIGHT;
}
.p-politica{
    font-size: 18px;
    line-height: 30px;
    font-family: POPPINS-LIGHT;
}
.titulo-2-polit{
    font-size: 18px;
    font-family: POPPINS-LIGHT;
    font-weight: bold;
}
.lista-1{
    list-style: lower-latin;
    font-size: 18px;
    font-family: POPPINS-LIGHT;
    line-height: 30px;
}
.lista-2{
    list-style: decimal;
    font-size: 18px;
    font-family: POPPINS-LIGHT;
    line-height: 30px;
}
.lista-3{
    list-style: square;
    font-size: 18px;
    font-family: POPPINS-LIGHT;
    line-height: 30px;
}
ol{
    padding: 0px 50px 0px;
}
.p-politica a{
    text-decoration: none;
    color: #000000;
}
.creditos-politicas{

}
.creditos-p{

}
@media (min-width: 1600px){

}
@media (max-width:1024px){
  .contenedor-p-politica{
    padding: 100px 50px 100px;
  }
}
@media (max-width:550px){
    .contenedor-p-politica{
        padding: 100px 40px 100px;
    }
}