.modalVideo{
    width: 100%;
    height: 100vh;
    position: fixed;
    /* background-color: rgba(0, 0, 0, 0.548); */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.fondo_black{
    background-color: rgba(0, 0, 0, 0.548);
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
}
.close{
    position: absolute;
    top:20px;
    z-index: 99;
    right: 35px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: none;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
}
svg{
    width: 100%;
    height: 100%;
    color: #F7F1EB;
}
.video2{
    width: 90%;
    height: 80%;
    position: relative;
}
video{
    width: 100%;
    height: 100%;  
}