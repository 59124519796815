.contenedor_stiky{
    width: 45px;
    height: 90px;
    position: fixed;
    left: 5px;
    top: 45%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.whatsapp{
    width: 230px;
    height: 60px;
    background-color: transparent;
    display: flex;
    color: #000000;
    transform: matrix(0, -1, 1, 0, 0, 0);
    font-size: 15px;
    font-family: POPPINS-LIGHT;
    justify-content: center;
    align-items: center;
}
.cotiza{
    margin-left: 20px;
    color: #000000;
    font-weight: bolder;
}
.agenda{
margin-right: 20px;
color: #000000;
font-weight: bolder;
}
.contenedor_stiky .whatsapp a{
    text-decoration: none;
}
.barra{
    height: 20px;
    width: 3px;
}
@media (min-width:1600px){

}
@media (max-width:1024px){

} 
@media (max-width:550px){
.contenedor_stiky{
    left: -5px;
    display: none;
}
.whatsapp{
    font-size: 12px;
}
}