.txt-contacto{
    font-size: 45px;
    font-family: MONTSERRAT-LIGHT;
    text-align: center;
    color: #000000;
    letter-spacing: 0px;
    padding-top: 30px;
    width: 100%;
}
.input{
    width: 400px;
    height: 9vh;
    padding-left: 10px;
    color: #664125;
    font-size: 16px;
    outline: none;
    display: block;
    font-family: POPPINS-LIGHT;
    border-bottom: 1px solid #4A251E !important;
    margin-right: 7px;
    margin-left: 50px;
}
.select-form{
    background-color: transparent;
    width: 80%;
    height: 61px;
    padding-left: 10px;
    margin-top: 30px;
    color: #664125;
    font-size: 16px;
    display: block;
    font-family: POPPINS-LIGHT;
    border: 1px solid #4A251E;
    margin-right: 7px;
    margin-left: 50px;
    appearance: auto;
}
.form-contenedor{
        width: 100%;
        height: 100vh;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-bottom: 80px;
}
.btn-form{
    background-color: transparent;
    color: #231F20;
    font-family: POPPINS-LIGHT;
    font-size: 17px;
    width: 130px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid #231F20;
}
.btn-form:hover{
    background-color: #231F20;
    color:#F7F1EB;
    border-color: #231F20;
}
button:disabled{
    opacity: 0.35;
}
.row{
    margin-top: 30px;
    justify-content: space-between
}
.error-message{
    color: #231F20;
    text-align: center;
    font-weight: bold;
    font-family: POPPINS-LIGHT;
    font-size: 15px;
}
.recaptchaes{
    padding: 0px 50px;
}
.terminos{
    color: #664125;
    font-family: POPPINS-LIGHT;
    font-size: 14px;
    margin-top: 40px;
    width: 40%;
    margin-left: 35px;
    hyphens: none;
}
.politica{
    text-decoration: underline;
}
.politica a:hover{
    color: #664125;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.politicat{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


input[type=number] { -moz-appearance:textfield; }

@media (min-width: 1600px){
    .form{
        width: 100%;
    }
    .input{
        font-size: 22px;
        width: 670px;
    }
    .row .column .select-form option{
        font-size: 20px;
    }
    .select-form{
      width: 100%;
    }
    .form-contenedor{
        padding: 20px 40px 20px;
    }
    .btn-form{
        margin-left: 0px;
        margin-top: 14px;
        margin-right: -6px;
        width: 15%;
        height: 7vh;
        font-size: 22px;
    }
    .txt-contacto{
        font-size:55px;   
    }
    .terminos{
        font-size: 19px;
        margin-top: 58px;
        line-height: 31px;
    }
  }
  @media (min-width: 1900px){
    .form{
        width: 85%;
    }
}
  @media (max-width:1024px){
    .form{
        width: 100%;
        padding: 0px 60px 70px
    }
    .input{
        font-size: 19px;
        width: 550px;
    }
    .form-contenedor{
        padding: 20px 40px 20px;
    }
    .select-form{
      width: 60%;
    }
    .row .column .select-form option{
        font-size: 10px;
    }
    .btn-form{
        margin-left: 0px;
        margin-top: 39px;
        margin-right: 32px;
        width: 30%;
        height: 5vh;
    }
    .recaptchaes{
        padding: 0px 50px;
    }
    .txt-contacto{
        padding-top: 70px;
        font-size: 45px;   
    }
  }
  @media (max-width:550px){
    .txt-contacto{
        font-size: 30px;
        padding-top: 15px;
    }
    .form-contenedor{
        justify-content: space-between;
        height: auto;
        padding: 15px 0px 15px;
    }
    .form{
        padding: 10px 43px;
    }
    .btn-form{
        margin-top: 15px;
    }
    .row{
        margin: 0px !important;
        width: 100%;
    }
    .column{
        width: 100%;
    }
    .input{
        width: 100%;
        font-size: 15px;
        margin: 0px;
    }
    .select-form{
        font-size: 15px;
        width: 100%;
        height: 5vh;
        margin: 40px 0px 0px;
    }
    .politicat{
        flex-direction: column;
        align-items: flex-start;
    }
    .terminos{
        font-size: 13px;
        width: 100%;
        margin: 5px 0px 0px;
    }
    .btn-form{
        margin-right: 30px;
        font-size: 15px;
    }
    .recaptchaes{
        padding: 0px;
        margin-top: 44px;
    }
  }