.fondo{
    background-color: white;
    overflow: hidden;
}
.cont-home{
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 50px;
    position: relative;
    width: 100%;
}
.texto{
    color: #000000;
    text-align: center;
    margin-top: 50px;
    font-family: MONTSERRAT-LIGHT;
    letter-spacing: 0px;
    font-size: 65px;
}
.textos-home{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    box-sizing: border-box;
}
.texto2{
    width: 100%;
    font-size: 20px;
    font-family: POPPINS-LIGHT;
    color:#000000;
    text-align: center;
    line-height: 150%;
    hyphens: none;

}
.video{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.video video{
    height: 100%;
    object-fit: cover;
}
.fondo_video{
    width: 100%;
    height: 100%;
    position: absolute;
}
.play{
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 9;
    cursor: pointer;

}
@font-face {
    font-family: "MONTSERRAT-LIGHT";
    src: url(/src/Components/Fonts/MONTSERRAT-LIGHT.OTF);
}
@font-face{
    font-family: "POPPINS-LIGHT";
    src: url(/src/Components/Fonts/POPPINS-LIGHT.TTF)
}
@font-face {
    font-family: BIRTHSTONE-REGULAR;
    src: url(/src/Components/Fonts/BIRTHSTONE-REGULAR.OTF);
}
@media (min-width: 1600px){
    .textos-home{
        padding: 0px 400px;
    }
    .texto{
        margin-top: 30px;
    }
    .video{
        height: 75%;
        object-fit: cover;
    }
  }
  @media (max-width:1024px){
    .cont-home{
        height: 50vh;
        hyphens: none;
        padding: 50px;
    }
    .texto{
        font-size: 55px;
    }
    .texto2{
        font-size: 20px;
    }
    .textos-home{
        padding: 10px 40px;
    }
  }
  @media (max-width:550px){
    .cont-home{
        padding: 20px;
    }
    .video{
        height: 80%;
    }
    .fondo_video{
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .texto{
        font-size: 25px;
        margin-top: 0px;
    }
    .texto2{
        font-size: 15px;
        padding: 0px 13px;
        width: 100%;
    }
    .textos-home{
        padding: 0px 0px 0px;
    }

  }
  @media (max-width:390px){
    .texto2{
        height: auto;
    }
}
  @media (max-width:360px){

    }
  @media (max-width:280px){
  .cont-home{
    height: auto;
  }
  }


    
