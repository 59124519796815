.d-cotizacion{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.cocina{
    position: absolute;
    margin-left: 135px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.txt-cocina{
    color: #FFFFFF;
    font-size: 86px;
    font-family: BIRTHSTONE-REGULAR;
    text-align: left;
    letter-spacing: 0px;
    text-shadow: 0px 3px 6px #00000029;
}

.txt-cocina2{
    color: #FFFFFF;
    font-size: 16px;
    font-family: POPPINS-LIGHT;
    text-align: left;
    letter-spacing: 0px;
    line-height: 150%;
    opacity: 1;
    z-index: 1;
    hyphens: none;
    margin-left: 135px;
    margin: 20px 0px 0px;
    width: 50%;
}
.d-cotizacion .cocina a{
    text-decoration: none;
}
.btn-cocina:hover{
    background-color: #FFFFFF;
    color: #000;
    border-color: #FFFFFF;
}
.btn-cocina{
    background: #000 ;
    border: 2px solid #231F20;
    color: #F7F1EB;
    font-family: POPPINS-LIGHT;
    font-size: 17px;
    width: 350px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: 70px 0px;
    }
.img-cocina{
    width: 100%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    position: relative;
}
@media (min-width:1600px){
    .cocina{
        height: 65%;
    }
    .txt-cocina{
        font-size: 115px;
    }
    .txt-cocina2{
        font-size:22px;
        width: 45%;
    }
}
@media (max-width:1024px){
    .btn-cocina{
        margin: 0px;
    }
    .txt-cocina{
        width: 80%;
    }
    .txt-cocina2{
        width: 100%;
        margin: 0px;
        font-size: 18px;
        padding: 40px 0px 40px;
    }
    .cocina{
        margin:0px 20px;
        height: 520px;
        justify-content: center;
        padding: 20px 70px 20px;
        width: 100%;
    }
    .d-cotizacion{
        height:70vh;
        align-items: center;
    }
  }
@media (max-width:550px){
    .btn-cocina{
        margin: 0px;
        width: 280px;
        padding: 15px;
        font-size: 15px;
        margin-top: 20px;
        height: 40px;
    }
    .txt-cocina{
        font-size: 45px;
        width: 100%;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
            text-align: center;
    }
    .txt-cocina2{
        width: 100%;
        font-size: 15px;
        margin: 0px;
    }
    .cocina{
        margin:0px 0px;
        height: 340px;
        justify-content: space-between;
        padding: 20px;
    }
    .d-cotizacion{
        height:500px;
        align-items: center;
    }
}
@media (max-width:280px){
.txt-cocina{
    font-size: 39px;
}
.txt-cocina2{
    font-size: 16px;
}
}