#app { height: 100% }
html,
body {
  height: 100%;
}


.swiper {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
@media (max-width:500px){
  .swiper{
    width: 30%;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.estilos{
    width: 100vw;
    height: 100vh;
    object-fit: contain;
}
@media screen and (max-width:1024px){
  .swiper-slide img {
        width: 100%;
        object-fit: cover;
        margin-top: 60px;
    }
  }

@media (max-width: 550px) {
  .swiper-slide{
  width: 10%;
  height: 100%;
}
.swiper-slide img {
  width: auto;
}

}



